@use "sass:color";

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

/* Color palette */
// TODO: use color relationships to finalize palette
// TODO: add Bulma dark mode support with blue
$am-blue: #264653;
$am-green: #2C3D32;
$am-brown: #4B3C32;
$am-gray: #444441;

// TODO: make these into a mixin?
$am-brown-lightish: color.adjust($am-brown, $lightness: 16%);
$am-brown-light: color.adjust($am-brown, $lightness: 20%);
$am-brown-lighter: color.adjust($am-brown, $lightness: 34%);
$am-brown-lightest: color.adjust($am-brown, $lightness: 44%);

/* Homepage, link, etc. colors */
.rxl-hero {
    color: white;
    // background: linear-gradient(-63.43deg, $am-yellow 0%, $am-yellow 10%, $am-orange2 calc(10% + 1px), $am-orange2 20%, $am-orange calc(20% + 1px), $am-orange 30%, $am-blue calc(30% + 1px), $am-blue 100%);
    background-color: $am-blue !important;

    a.am-social-url {
        color: $am-brown-lightest;

        &:hover {
            color: $am-brown-lighter;
        }
    }
}

.am-homepage-btn {
    background-color: $am-brown-light;
    border-color: $am-brown-light;
    color: white;

    &:hover {
        background-color: $am-brown-lightish;
        border-color: $am-brown-lightish;
    }
}

/* Footer stuff */
.am-footer-subtitle {
    margin-bottom: 0.5rem !important;
}

.am-footer-small {
    line-height: 1rem !important;
}

.footer {
  background-color: $am-blue !important;
  padding-bottom: 3rem !important;
}

/* Keep the footer sticky at the bottom of the page */
#am-flex-page-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#am-flex-content-wrapper {
    flex: 1;
}

/* Font stuff */
html {
    font-size: 17px;
}

body, button, input, select, textarea {
    font-family: 'Rubik', serif;
}
.rxl-white-text, .navbar-burger-white, .hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
    color: white;
}

.font-header {
    font-family: 'Merriweather', serif;
}

.font-mono {
    font-family: 'Inconsolata', 'Courier New', Courier, monospace;
}

// TODO: add monospace support
// .font-mono {
//     font-family: monospace;
// }

/* Language colors
 * Colors from https://github.com/ozh/github-colors
 */
@mixin lang($language, $color) {
    .lang-#{$language} {
        border-bottom: medium solid $color;
        margin-right: 5px;
    }
}
// Keep these alphabetical
@include lang("c", #555555);
@include lang("css", #563d7c);
@include lang("go", #00add8);
@include lang("html", #e34c26);
@include lang("java", #b07219);
@include lang("javascript", #f1e05a);
@include lang("php", #4f5d95);
@include lang("python", #3572a5);
@include lang("typescript", #3178c6);

/* Other stuff for projects */
.indent-col {
    max-width: 75px;
}
.linkicon {
    margin-right: 5px;
}
hr {
    background-color: #ccc;
    height: 1px;
    .project-hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

/* Bulma overrides
$body-background-color: #000;
@import url('https://cdn.jsdelivr.net/npm/bulma@0.8.2/bulma.sass');
*/


// // Set your brand colors
// $purple: #8a4d76;
// $pink: #fa7c91;
// $brown: #757763;
// $beige-light: #d0d1cd;
// $beige-lighter: #eff0eb;

// // Override global Sass variables from the /utilities folder
// @use "../node_modules/bulma/sass/utilities" with (
//   $family-primary: '"Merriweather", serif',
//   $family-secondary: '"Rubik", sans-serif',
//   $grey-dark: $brown,
//   $grey-light: $beige-light,
//   $primary: $purple,
//   $link: $pink,
//   $control-border-width: 2px
// );

// // Override Sass variables from the /form folder
// @use "../node_modules/bulma/sass/form" with (
//   $input-shadow: none
// );

// // Import the components you need
// @forward "../node_modules/bulma/sass/base";
// @forward "../node_modules/bulma/sass/components/card";
// @forward "../node_modules/bulma/sass/components/modal";
// @forward "../node_modules/bulma/sass/components/navbar";
// @forward "../node_modules/bulma/sass/elements/button";
// @forward "../node_modules/bulma/sass/elements/icon";
// @forward "../node_modules/bulma/sass/elements/content";
// @forward "../node_modules/bulma/sass/elements/notification";
// @forward "../node_modules/bulma/sass/elements/progress";
// @forward "../node_modules/bulma/sass/elements/tag";
// @forward "../node_modules/bulma/sass/layout/footer";

// // Import the themes so that all CSS variables have a value
// @forward "../node_modules/bulma/sass/themes";

// // Import the Google Font
// @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
